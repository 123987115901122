.footer_wrapepr {
  ul {
    padding-left: 0;
  }

  font-size: 0.7rem;
  background-color: #f5f5f5;

  .footer_list_wrapper {
    font-weight: bold;
    width: 80%;
    padding: 3rem 3rem;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    li {
      margin-right: 1rem;
    }

    .footer_list_in_wrapper {
      margin-top: 1rem;

      li {
        color: #9b9b9b;
        padding: 0.2rem 0;
        a {
          color: #9b9b9b;
          padding: 0.2rem 0;
        }
      }
    }
  }

}

.footer_bottom_wrapper {
  .footer_info_wrapper {
    padding: 2rem 3rem;
  }
}
