.customer_wrapper {
  padding: 3rem 0;

  .nav-item {
    text-align: center;
    margin: 0 !important;
    width: 50%;
    cursor: pointer;

    .active {
      color: #63ACB5 !important;
      border-top: 1px solid #63ACB5;
      border-left: 1px solid #63ACB5;
      border-right: 1px solid #63ACB5;
    }

    .nav-link {
      color: #9b9b9b;
    }
  }

  .customer_notice_wrapper {
    padding: 5rem 1rem;

    .notice_title {
      padding-bottom: 4rem;

      h4 {
        color: #9b9b9b;
      }
    }

    .ul_notice {
      padding-left: 0;
      border-top: 1px solid black;

      li {
        display: flex;
        flex-direction: row;

        border-bottom: 1px solid #c8c8c8;
        align-items: center;
        justify-content: center;

        &:hover {
          background-color: rgba(99, 172, 181, 0.1);
        }

        .notice_item_no {
          border-right: 1px solid #c8c8c8;
          padding: 0.5rem 0;
          text-align: center;
          width: 10%;

          .notice_no_back {
            border-radius: 50px;
            background-color: #63ACB5;
            width: 70%;
            padding: 0.5rem 0;
            margin: 0 auto;
          }
        }

        .notice_item_title {
          border-right: 1px solid #c8c8c8;
          padding: 1rem 0;
          width: 70%;

          span {
            font-size: 1.2rem;
            padding-left: 1.5rem;
          }
        }

        .notice_item_date {
          color: #9b9b9b;
          text-align: center;
          width: 20%;
          padding: 1rem 0;
        }
      }

    }
  }

  .customer_faq_wrapper {
    padding: 5rem 1rem;
    .faq_section{
      padding: 2rem 0;
    }
    .faq_title {
      h4 {
        color: #9b9b9b;
      }
    }

    .faq_item_wrapper {
      .faq_item{
        display: flex;
        justify-content: space-between;
        .rotate_arrow{
          transform: rotate(0);
        }
        img{
          transform: rotate(-90deg);
          margin-right: 1rem;
          width: 15px;
        }
      }
      h4 {
        color: #63ACB5;
      }

      .ul_faq {
        margin-top: 2rem;

        li {
          .toggle_wrapper{
            padding: 2rem 0;
            background-color: #f6f6f6;
           .toggle_content_wrapper{
              p{
                font-size: 1rem;
                font-weight: lighter;
                padding: 0 2rem;
                margin-bottom: 0;
              }
           }
          }
          &:first-child {
            button {
              border-top: 1px solid #c3c3c3;
            }
          }

          button {
            padding: 1rem 0;
            width: 100%;
            background-color: white;
            border: none;
            color: black;

            border-bottom: 1px solid #c3c3c3;
            text-align: left;
            border-radius: 0;
            &:focus{
              outline: none;
              box-shadow: none;
            }
            span {
              margin-left: 2rem;
              font-weight: lighter;
            }
          }
        }
      }
    }


  }
}
@media screen and (max-width: 768px) {
  .customer_wrapper{
    .customer_notice_wrapper{
      padding: 5rem 0!important;
      .ul_notice{
        .notice_item_no{
          width: 20% !important;
        }
        .notice_item_title{
          border-right: none!important;
          width: 80% !important;
        }
        .notice_item_date{
          display: none;
        }
      }
    }
    .customer_faq_wrapper{
      .ul_faq{
        padding: 0;
      }
    }
  }
}
