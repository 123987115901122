
.landing_main_img_wrapper {
  img {
    width: 100%;
  }
}

.landing_best_wrapper {
  padding: 6rem 0;

  .landing_best_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .landing_best_item_content {
      display: flex;
      flex-direction: column;
      text-align: center;

      .stick_container {
        padding-top: 1.3rem;
        margin: 0 auto;

        #back_stick {
          width: 2px;
          height: 60px;
          background-color: black;
        }
      }

      .product_txt {
        padding-top: 2rem;

        hr {
          width: 6rem;
          margin: 0 !important;
        }
      }

      .small_txt {
        padding-top: 1rem;

        span {
          color: #9b9b9b;
        }
      }
    }

    .best_item_more {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 1rem;

      h4 {
        margin: 0 !important;
      }

      img {
        margin-left: 0.4rem;
        width: 15px;
      }
    }

    .landing_best_item_wrapper {
      padding: 3rem 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;

      .best_item {
        width: 100%;
        overflow: hidden;

        .img_wrapper {
          width: 98%;
          margin: 0 auto;

          .main_img {
            &:hover {
              cursor: pointer;
              -webkit-transform: scale(1.1); /*  크롬 */
              -moz-transform: scale(1.1); /* FireFox */
              -o-transform: scale(1.1); /* Opera */
              transform: scale(1.1);
              transition: transform .35s;
              -o-transition: transform .35s;
              -moz-transition: transform .35s;
              -webkit-transition: transform .35s;
            }

            object-fit: contain;
            width: 100%;
          }
        }

        .best_item_desc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row;
          padding: 1rem 3rem;
          color: white;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.3);

        }
      }
    }
  }
}

.landing_section_img_2 {
  padding: 4rem 0;
}

#main_logo_img {
  padding: 2rem 0;
}

.landing_section_img {
  height: 400px;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  img {
    width: 100%;
  }

  .landgin_section_img_wrapper {
    min-height: 400px;
    position: relative;
    //background-image: url("src/asset/img/landing_section_img.png");
    .img_txt {
      position: absolute;
      display: flex;
      flex-direction: column;
      left: 15%;
      bottom: 10%;

      span {
        color: white;
        padding: 0.4rem 0;

        &:first-child {
          font-size: 4rem;
        }

        &:nth-child(2) {
          font-size: 1.5rem;
        }

        &:last-child {
          font-size: 1.2rem;
          text-decoration: underline;
        }
      }
    }
  }
}

.landing_slick_wrapper {
  padding: 5rem 0;

  .slick-slider {
    width: 80%;
    margin: 0 auto;
  }

  img {
    width: 100%;
  }
}

.landing_notice_wrapper {
  padding: 5rem 0;

  .landing_notice_header {
    .landing_header_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      a {
        color: #63ACB5;
      }

      img {
        width: 15px;
      }
    }
  }

  .landing_notice_content {
    display: flex;
    justify-content: space-between;

    .landing_notice_item {
      width: 40%;

      .notice_top_txt {
        border-left: 3px solid #9b9b9b;

        h3, span {
          padding-left: 2rem;
        }
      }
    }
  }
}

.stretcher {
  height: 500px;
  position: relative;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  font-size: 0;
  margin: 0;
  padding: 0;

  li {
    -webkit-transition: width .5s;
    transition: width .5s;
  }

  .stretcher-item {
    float: left;
    height: 100%;
    width: 23%;
    background-size: cover;
    background-position: center;
    position: relative;
    cursor: pointer;
    &.active {
      width: 38% !important;
    }

    &:hover {
      figure {
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    figure {
      background-color: rgba(99, 172, 181, .5);
      color: #fff;
      position: absolute;
      z-index: 9;
      font-size: initial;
      padding: 20px 30px;
      width: 100%;
      left: 0;
      bottom: -3%;
      -moz-transform: translate3d(0, 100%, 0);
      -ms-transform: translate3d(0, 100%, 0);
      -o-transform: translate3d(0, 100%, 0);
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      -moz-transition: all .5s;
      -o-transition: all .5s;
      -webkit-transition: all .5s;
      transition: all .5s;
    }
  }

  //.active{
  //  width: 38% !important;
  //}
  .inactive {
    width: 18% !important;
  }

  .more {
    float: left;
    height: 100%;
    background-size: cover;
    background-position: center;
    width: 8%;
    background-color: #63ACB5;

    .plus_logo {
      position: relative;
      width: 100%;
      height: 100%;
      cursor: pointer;

      h4 {
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
      }
    }

    &.active {
      width: 28% !important;
    }
  }
}

.landing_carousel_wrapper {
  height: 100vh;

  .carousel-root {
    height: 100%;

    .carousel-slider {
      height: 100%;
    }
  }

  .landing_carousel_item {
    position: relative;

    .item_0 {
      color: white;
    }

    .item_1 {
      color: #5d5d5d;
    }

    .carousel_txt_wrapper {
      text-align: left;
      position: absolute;
      top: 20%;
      left: 20%;

      h2 {
        font-size: 5rem;
      }

      h4 {
        margin-left: 1rem;
      }
    }
  }
}

.landing_mat_detail_wrapper {
  text-align: center;
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .mat_detail_top {
    display: flex;
    flex-direction: column;
    font-weight: bold;

    h2 {
      margin: 1rem 0;
    }

    small {
      color: #B0B0B0;
    }

    span {
      font-weight: bold;
      color: #B0B0B0;
      font-size: 1.3rem;
    }
  }

  .mat_detail_bottom {
    width: 100%;
    padding-top: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    .icon_content {
      img {
        width: 70px;
        height: 70px;
      }

      #book_brand {
        height: 105px;
      }
    }

    .left_detail_content {
      display: flex;
      flex-direction: column;
      width: 28%;

      .detail_content_item {
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .txt_content {
          width: 60%;
          word-break: break-all;
          margin-right: 2rem;
        }
      }

    }

    .center_detail_content {
      text-align: center;
      width: 44%;

      img {
        width: 100%;
      }
    }

    .right_detail_content {
      display: flex;
      flex-direction: column;
      width: 28%;

      .go_story_wrapper {
        align-items: center;

        span {
          a {
            color: #63ACB5;
          }
        }

        button {
          margin-top: 1rem;
          padding: 1rem 2.4rem;
          background: none;
          border: 1px solid #63ACB5;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          position: relative;

        }

        button::after {
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
        }

        button::before,
        button::after {
          background: #63ACB5;
          content: '';
          position: absolute;
          z-index: -1;
        }

        button:hover {
          color: white;
        }

        button::after {
          height: 0;
          left: 0;
          top: 0;
          width: 100%;
        }

        button:hover:after {
          height: 100%;
        }
      }

      .detail_content_item {
        height: 50%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .txt_content {
          width: 60%;
          word-break: break-all;
          margin-left: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .nav-container {
    justify-content: flex-start;

    .nav-icon {
      padding: 0 1.7rem;

      img {
        width: 25px;
        height: 25px;
      }

    }

    #main_logo_img {
      padding: 1rem 0;
    }
  }

  .landing_nav, .navbar {
    background-color: white !important;
  }
  .landing_container {
    margin-top: 65px;

    .landing_carousel_wrapper {
      height: 100%;

      .carousel_txt_wrapper {
        display: none;
      }
    }

    .landing_best_wrapper {
      padding: 3rem 0;
    }

    .landing_best_item_wrapper {
      .stretcher {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        height: 700px;

        .more {
          width: 100%;
          height: 50%;
        }

        .stretcher-item {
          width: 100%;

          &:nth-child(3),
          &:nth-child(4) {
            display: none;
          }

          figure {
            //display: none;
            bottom: 0;
            background-color: rgb(123 123 123 / 50%);
            transform: none;
            transition: none;
            margin: 0;
          }
        }
      }
    }

    .mat_detail_bottom {
      flex-direction: column;

      .center_detail_content {
        order: -1;
        width: 100%;
      }

      .left_detail_content,
      .right_detail_content {
        width: 100%;

        .detail_content_item {
          padding: 2rem 0;
          justify-content: center;
          flex-direction: column;

          .txt_content {
            width: 100%;
            margin: 0 !important;

            h4 {
              margin-top: 1rem;
            }

            h4, p {
              padding: 0 1rem;
            }
          }
        }
      }

      .left_detail_content {

        .icon_content {
          order: -1;
        }
      }
    }

    .landing_notice_content {
      justify-content: center;

      .landing_notice_item {
        width: 90%;
      }
    }

  }
  .landing_notice_wrapper {
    padding: 5rem 1rem;
  }
  .footer_top_wrapper {
    display: none;
  }
  .landing_section_img {
    .img_txt {
      left: 5% !important;
      top: 10% !important;

      span {


        &:first-child {
          font-size: 3.4rem !important;
        }

        &:nth-child(2) {
          font-size: 1.2rem !important;
        }

        &:last-child {
          margin-top: 1.7rem;
          font-size: 1.5rem !important;
          text-decoration: underline;
        }
      }
    }
  }
}

