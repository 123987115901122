a{
  text-decoration: none;
  color: black;
  &:hover{
    color: black;
  }
}
ul{
  list-style: none;
}
.main_color{
  color: #63ACB5;
}
.container{
  margin-top: 65px;
}
