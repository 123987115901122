.brand_story_wrapper {
  max-width: 1040px;
  display: flex;
  flex-direction: column;

  .top_txt_wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    hr {
      width: 2px;
      height: 70px;
    }
  }


  .part_1_wrapper {
    padding: 4rem 0;

    p {
      font-size: 1.2rem;
    }

    .part_title {
      text-align: center;

      span {
        color: #B9B9B9;
        vertical-align: middle;
        font-weight: bold;
        font-size: 1.4em;
      }

      img {
        margin-left: 1rem;
        width: 25px;
      }
    }

    .part_item {
      padding-top: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .left_sec {
        width: 30%;
      }
    }

    .part_item_re {
      padding-top: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .right_sec {
        width: 30%;
      }
    }
  }

  .part_2_wrapper {
    padding: 4rem 0;

    .part_title {
      text-align: center;

      span {
        color: #B9B9B9;
        vertical-align: middle;
        font-weight: bold;
        font-size: 1.4em;
      }

      img {
        margin-left: 1rem;
        width: 25px;
      }
    }

    .part_content_wrapper {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;

      .left_sec {
        td {
          text-align: center;

          img {
            width: 250px;
          }

          #backache_img {
            margin-top: 3rem;
            margin-left: 6rem;
            height: 150px;
            width: 200px;
          }
        }

        .left_img_wrapper {
          position: relative;
          padding: 4rem 1rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .no_img {
            position: absolute;
            width: 130px;
          }

          .circle_img {
            position: absolute;
            width: 300px;
            left: -10%;
            top: 5%;
          }
        }
      }

      .right_sec {
        width: 40%;

        .why_icon_wrapper {
          text-align: center;
          margin-bottom: 2rem;

          img {
            width: 100px;
          }
        }
      }

      .part_content_title {
        font-size: 1.2rem;

        .color_box {
          vertical-align: middle;
          margin-right: 0.5rem;
          display: inline-block;
          width: 15px;
          height: 15px;
          background-color: #63ACB5;
        }

        span {
          vertical-align: middle;
        }
      }

      .part_content_box {
        margin-top: 2rem;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 40px;

        p {
          padding: 2rem 1rem;
        }
      }

    }

    .part_2_bt_wrapper {
      margin-top: 5rem;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      overflow: hidden;

      .part_2_bt_item {
        position: relative;
        min-width: 300px;
        min-height: 310px;
        box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        border-radius: 30px;

        .bt_title {
          text-align: center;
          padding: 1.5rem 3rem;

          span {
            font-size: 1.3rem;
            font-weight: bold;
          }
        }

        .bt_content {
          height: 100%;
          background-size: contain;
          text-align: center;

          p {
            color: black;
            font-weight: bold;
            padding-top: 2rem;
          }
        }

        #bt_2_img {
          position: absolute;
          right: 0;
          width: 140px;
        }

        #bt_2_content {
          font-weight: bold;
          text-align: left;
          position: absolute;
          left: 3%;
          bottom: 6%;
        }

        #bt_3_content {
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .part_1_wrapper {
    padding: 4rem 1rem!important;
    .part_item,
    .part_item_re {
      flex-direction: column !important;

      .left_sec,
      .right_sec {
        width: 100% !important;

        img {
          width: 100%;
        }
      }
    }

    .part_item_re {
      .right_sec {
        order: -1;
      }
    }
  }
  .part_2_wrapper {
    padding: 2rem 1rem!important;
    .part_content_wrapper {
      flex-direction: column !important;
      align-items: center!important;
      table {
        tr {
          display: flex;
          flex-direction: column;
          .left_img_wrapper{
            padding: 1rem 0!important;
          }
        }
      }
    }
    .right_sec{
      width: 100% !important;
    }
    .part_2_bt_wrapper{
      flex-direction: column!important;
      .part_2_bt_item{
        display: flex;
        flex-direction: column;
        margin: 1rem 0;
        #bt_2_img{
          top: 40%;
        }
        .bt_title{
          flex-grow: 2;
        }
        .bt_content{
            flex-grow: 8;
          background-size: cover!important;
        }
      }
    }
  }
}
