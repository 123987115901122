.detail_img_border {
  border: 2px solid #63ACB5 !important;
}

.detail_wrapper {
  padding-top: 5rem;

  .detail_top_wrapper {
    .detail_img_wrapper {
      width: fit-content;

      .big_img_wrapper {
        padding-right: 3rem;

        img {
          width: 100%;
          height: 540px;
        }
      }

      .small_img_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: row;
        padding-top: 2.5rem;

        img {
          margin-right: 1rem;
          border: 1px solid white;
          width: 102px;
          height: 102px;
          cursor: pointer;

          &:hover {
            border: 2px solid #63ACB5;
          }
        }

      }
    }

    .detail_info_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      hr {
        margin-top: 1rem;
        margin-bottom: 0;
      }

      .detail_info_price {
        .price_per {
          text-decoration: none;
          color: #db4747;
        }

        span {
          color: #acacac;
          text-decoration: line-through;
        }
      }

      .detail_info_shipping {
        padding-top: 2rem;

        label {
          color: rgba(0, 0, 0, 0.54);
        }

        span {
          margin-left: 3rem;
        }
      }

      .detail_info_size {
        padding-top: 1rem;
        display: flex;
        flex-direction: row;

        label {
          color: rgba(0, 0, 0, 0.54);
        }

        button {
          margin-left: 1rem;
          background-color: white;
          color: #000000;
          border: 1px solid #63ACB5;
          margin-top: 2.4rem;
        }
      }

      .order_list_wrapper {
        width: 100%;

        .order_list_item {
          width: 100%;
          display: flex;
          flex-direction: row;
          margin-bottom: 1rem;
          border-bottom: 1px solid #9b9b9b;
          padding: 0.5rem 0;

          .order_info {
            display: flex;
            flex-direction: column;
            flex-grow: 6;
            #order_title_info{
              font-weight: bold;
            }
            #order_size_info{
              color: #999;
              font-size: 0.9rem;
            }
          }

          .order_count {
            flex-grow: 4;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .count_btn,
            .count_txt {
              vertical-align: middle;
              text-align: center;
              display: inline-block;
              width: 30px;
              height: 30px;
              background: none;
              border: 1px solid #9b9b9b;
            }
            .count_btn{
              line-height: 5px;
            }
            .count_txt {
              line-height: 26px;
            }
          }

          .order_list_cancel_wrapper {
            display: flex;
            align-items: center;

            .order_cancel_btn {
              width: 20px;
              height: 20px;
              line-height: 9px;
              border: 1px solid #9b9b9b;
            }
          }
        }
      }

      .detail_info_btn_wrapper {
        width: 100%;

        .last_price_wrapper {
          text-align: right;
        }

        .btn_container {
          margin-top: 2rem;
          display: flex;
          justify-content: space-around;

          button {
            padding: 0.8rem 0;

            &:first-child {
              width: 18%;
              background-color: white;
              color: #63ACB5;

              img {
                width: 40px;
              }

              //&:hover {
              //  background-color: #63ACB5;
              //  color: white;
              //}
            }

            &:last-child {
              width: 70%;
              background-color: #63ACB5;
              color: white;

              //&:hover {
              //  background-color: white;
              //  color: #63ACB5;
              //
              //}
            }
          }
        }
      }
    }

  }

  .detail_bottom_wrapper {
    padding-top: 5rem;

    .detail_tab_wrapper {
      .detail_tab {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
      }

      .nav-tabs {
        .nav-item {
          text-align: center;
          margin: 0 !important;
          width: 25%;

          .active {
            border-left: none;
            border-top: none;
            border-right: none;
            border-bottom-width: 2px;
            border-color: #63ACB5 !important;
            color: #63ACB5 !important;
          }

          .nav-link {
            color: black;
          }
        }
      }

      .detail_main_img_wrapper {
        text-align: center;
      }
      .sleep_maker_detail_wrapper{
        max-width: 800px;
        margin: 0 auto;
        .sleep_maker_movie_wrapper{
          video{
            margin: 3.4rem 0;
            width: 100%;
          }
          .sleep_maker_advantage{
            margin: 2.4rem 0;
            .advantage_header{
              text-align: left;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              .blank_advantage{
                margin-right: 1rem;
                width: 24px;
                height: 24px;
                background-color: #88C0C7;
              }
            }
            .advantage_content{
              display: flex;
              flex-direction: row;
              .advantage_item{
                padding: 1rem;
                width: 50%;
                img{
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .detail_review_wrapper {
        padding-top: 4rem;

        .review_header_left,
        .review_header_right {
          padding: 2rem 0
        }

        .review_header_right {
          text-align: right;

          span {
            color: #9b9b9b;
            padding: 0 0.5rem;
          }
        }

        .review_item {
          padding: 0.6rem 3rem;
          border-bottom: 1px solid #9b9b9b;

          .review_item_header {
            color: #9b9b9b;
            font-size: 0.8rem;

            .star_wrapper {
              display: flex;
              align-items: center;

              .star_count {
                font-size: 1rem !important;
                color: black !important;
                font-weight: bold;
                margin-left: 0.4rem;
              }
            }

            .review_user_info {
              span {
                padding-right: 0.5rem;
              }
            }

          }

          .review_item_content {
            .review_content_img {
              margin-top: 1rem;

              img {
                width: 85px;
                margin-right: 0.3rem;
              }
            }

            .review_content_txt {
              padding: 1.5rem 0;

              p {
                font-size: 0.8rem;
              }
            }
          }

          #starImg {
            width: 15px;
          }
        }
      }
    }
  }


}

@media screen and (max-width: 768px) {
  .detail_wrapper {
    overflow: hidden;
    padding: 0 !important;

    .detail_top_wrapper {
      .detail_img_container {
        flex-direction: column;

        .detail_img_container {
          width: 100%;

          .big_img_wrapper {
            img {
              width: 100%;
            }
          }
        }

        .detail_info_wrapper {
          padding: 2rem 1rem;
          width: 100%;
        }
      }
    }

    .detail_main_img_wrapper {
      img {
        width: 100%;
      }
    }
  }
}
