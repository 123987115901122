.list_top_img_wrapper {
  position: relative;

  .list_top_back {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(206 206 206 / 40%)
  }

  .list_top_label {
    position: absolute;
    left: 18%;
    bottom: 5%;
  }

  img {
    width: 100%;
    height: 500px;
    object-fit: cover;
  }

}

.list_header_wrapper {
  padding: 2rem 1rem 0;

  .list_sort_btn {
    li {
      padding: 0 0.3rem;
      cursor: pointer;

      span {
        font-size: 0.9rem;
        color: #999999;
      }
    }

  }
}

.list_wrapper {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  padding-bottom: 5rem;

  .list_item_wrapper {
    margin-top: 2rem;
    max-width: 420px;
    .list_img_wrapper {
      img {
        width: 420px;
      }
    }

    .list_info_wrapper {
      .info_brand,
      .info_price {
        font-weight: bold;
        box-sizing: border-box;
      }
      .info_size{
        padding: 0.2rem 0;
        span{
          color: #999;
          display: inline-block;
          margin-right: 6px;
          width: 26px;
          height: 26px;
          border: 1px solid #ddd;
          padding: 0.1rem;
          font-size: 11px;
          text-align: center;
          vertical-align: top;
          line-height: 22px;
        }
      }
      .info_price {
        font-size: 1rem !important;

        .info_price_wrapper {
          width: 50%;
          display: flex;
          justify-content: space-between;

          span {

            &:last-child {
              order: -1;
            }

          }
        }

        .first_price {
          color: #acacac;
          text-decoration: line-through;
        }
      }

      .info_title,
      .info_brand {
        font-size: 0.8rem;
        padding: 0.2rem 0;
      }
    }
  }
}

.pagination_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  button {
    width: 34px;
    height: 34px;
    background-color: white;
    border: 1px solid #f1f1f1;
    color: gray;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #e7e7e7;
    }
  }

  img {
    width: 12px;
    -webkit-filter: opacity(.5) drop-shadow(0 0 0 gray);
    filter: opacity(.5) drop-shadow(0 0 0 gray);
  }

  .left_page_btn {
    img {
      transform: scaleX(-1);
    }
  }
}

@media screen and (max-width: 768px) {
  .list_container {
    margin-top: 65px;

    .list_top_img_wrapper {
      img {
        height: 300px !important;
      }

      .list_top_label {
        left: 5%;

        h2 {
          font-size: 1.2rem;
        }

        span {
          font-size: 0.7rem;
          color: #4c4c4c;
        }
      }
    }

    .list_sort_btn {
      justify-content: space-around !important;
      padding: 0;
    }

    .list_wrapper {
      padding: 0 1rem;

      .list_item_wrapper {
        .list_img_wrapper {
          img {
            width: 100%;
          }
        }

        .list_info_wrapper {
          .info_price {
            margin-top: 1rem !important;

            .info_price_wrapper {
              width: 66%;

              span {

                &:last-child {
                  order: -1;
                }

              }
            }
          }
        }
      }
    }
  }
}
