.showRoom_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .showRoom_list_wrapper {
    display: flex;
    flex-direction: column;

    .showRoom_list_item {
      padding: 1rem 0;
      border-bottom: 3px solid #bababa;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;

      span {
        color: #707070;
      }

      .showRoom_name {
        flex-grow: 1;
      }

      .showRoom_address {
        flex-grow: 4;
      }

      .showRoom_phoneNum {
        text-align: right;
        flex-grow: 1;
      }

      .showRoom_modal_btn {
        flex-grow: 1;

        button {
          float: right;
          padding: 0.5rem 2.4rem;
          background: none;
        }
      }
    }

    .not_open {
      .showRoom_address {
        flex-grow: 9;
        text-align: center;
      }
    }
  }
}

.showRoom_topImg_wrapper {
  position: relative;
  width: 100%;

  .showRoom_top_label {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: white;

    h2 {
      text-decoration: underline;
    }

    span {
      padding: 0.4rem 0;
    }
  }

  img {
    width: 100%;
    height: 300px;
    object-fit: cover;
  }
}

.showRoom_img_btn {
  margin-top: 5rem;

  button {
    border: 1px solid #63ACB5;
    padding: 0.8rem 5rem;
    color: #63ACB5;
    background-color: rgba(255, 255, 255, 0.1);
    transition-property: width, background-color;

    /* 변화 속도를 지정한다. */

    transition-duration: 0.5s, 0.5s;

    &:hover {
      background-color: #63ACB5;
      color: white;
    }
  }
}

.showRoom_modal_wrapper {
  max-width: 1140px !important;

  .modal-header {
    justify-content: flex-end;
    border-bottom: none;

    img {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }

  .showRoom_info_item {
    .item_row {
      align-items: center;
      justify-content: space-between;
      padding: 1rem 2rem;
    }

    .showRoom_map {
      #map {
        height: 400px;
      }
    }

  }

}

@media screen and (max-width: 768px) {
  .showRoom_container {
    margin-top: 65px;

    .showRoom_top_label {
      width: 100%;
    }

    .showRoom_list_item {
      align-items: flex-start !important;
      flex-direction: column !important;

      div {
        padding: 0.3rem 0;
      }

      .showRoom_modal_btn {
        width: 100%;
        margin-top: 1rem;

        button {
          border-color: #9b9b9b;
          width: 100%;
        }
      }

    }

  }
  .showRoom_info_item {
    .item_row {
      padding: 0!important;
      flex-direction: column !important;
      #map{
        height: 300px!important;
      }
      .showRoom_map, .showRoom_txt {
        width: 100%;
      }
    }
  }
}
