.register_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 5rem;
  .register_label{
    font-size: 1.5rem;
    font-weight: bold;
    padding: 4rem 0;
    text-align: center;
    width: 100%;
  }
  .register_form {
    width: 40%;
    .required_wrapper{
      text-align: right;
      font-size: 0.8rem;
    }
    .register_form_item{
      padding: 0.7rem 0;
    }
  }
  .register_check_wrapper{
    width: 40%;
    .show_register{
      color: #c1c1c1;
      text-decoration: underline;
      font-size: 0.8rem;
      cursor: pointer;
      margin-left: 0.5rem;
    }
    .form-check-input[type=checkbox]{
      border-radius: 50%;
    }
    .sms_select{
      margin: 0 1rem;
    }
    .checkbox_item{
      margin-left: 2rem;
      padding: 0.3rem 0;
    }
    .register_sub_btn{
      width: 100%;
      border-radius: 20px;
      margin: 3rem 0;
      padding: 0.5rem 0;
    }
  }
}
.required{
  color: red;
}
.border-danger {
  border-color: #e63757 !important
}
.agree_btn{
  color: white;
  padding: 0.5rem 7rem;
  background-color: #63ACB5;
  &:hover{
    color: white;
  }
}
