#naverBtn{
  cursor: pointer;
}

.login_wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .login_form_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: fit-content;
    width: 48.5%;
    min-width: 593px;
    max-width: 680px;
    .login_body{
      width: 100%;
    padding: 80px;
      border: 1px solid #f1f1f1;
      .login_btn_wrapper{
        text-align: right;
        button{
          max-height: 100px;
        }
      }
      .login_footer_btn_wrapper{
        button{
          width: 30.3%;
          height: 45px;
        }
      }
    }
  }
}
