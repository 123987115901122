.notice_view_header {
  padding: 5rem 0;
  h4{
    color: #9b9b9b;
  }
}

.notice_view_wrapper {
  border-top: 1px solid black;

  .notice_view_title {
    padding: 1rem 2rem;
    border-bottom: 1px solid #c8c8c8;
    display: flex;
    justify-content: space-between;
    .notice_title {
      font-weight: bold;
    }
    .notice_date {
      font-size: 0.9rem;
      color: #999999;
    }
  }

  .notice_view_content {
    min-height: 400px;
    padding: 3rem 2rem;
  }
}

.notice_view_btn_wrapper {
  display: flex;
  justify-content: center;
  padding: 3rem 0;

  .notice_view_btn {
    background-color: rgba(255, 255, 255, 0);
    border-color: #63ACB5;
    color: #63ACB5;
    padding: 0.5rem 0;
    width: 15%;
    &:hover{
      background-color: #63ACB5;
      color: white;
    }
  }
}
